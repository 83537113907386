import Vue from 'vue';
import VueForm from 'vue-form';
import VCalendar from 'v-calendar';
import 'jquery-address';
import 'owl.carousel/dist/owl.carousel';
import '@situation/setdesign.common';
import '@situation/setdesign.directives.owl-a11y';
import '@situation/setdesign.directives.sticky-header';
import '@situation/setdesign.directives.toggle';
import '@situation/setdesign.nav-menu';
import '@situation/setdesign.modal';
import '@situation/setdesign.video';
import '@situation/setdesign.tag-manager';
import '@situation/setdesign.read-more';
import '@situation/setdesign.accordion';
import '@situation/setdesign.sub-nav';
import '@situation/setdesign.filters';
import '@situation/setdesign.media-carousel';
import '@situation/setdesign.responsive-picture';
import '@situation/setdesign.lazy-image';
import '@situation/setdesign.message';
import '@situation/vc-library';
import {
    $, addAction, INIT, importAll, registerVueComponents,
} from '@situation/setdesign.util';

importAll(require.context('../images', true));
importAll(require.context('../fonts', true));
importAll(require.context('./components', false));
importAll(require.context('./components/show-filters', false));
addAction(INIT, () => {
    const VUE_APP_ID = '#app';
    if (!$(VUE_APP_ID).length) {
        return;
    }

    Vue.use(VueForm);
    Vue.use(VCalendar);
    registerVueComponents(require.context('./components/inquiry-form', false), Vue);
    registerVueComponents(require.context('./components/calendar', false), Vue);
    Vue.filter('monthToString', (value) => {
        const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        return monthNames[value - 1];
    });

    new Vue({}).$mount(VUE_APP_ID);
});
