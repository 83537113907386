<template>
<fieldset>
    <span class="inquiry-form__filters-select-icon">
        <span class="icon icon-chevron icon-style-primary icon-direction-up">
            <svg><use class="icon-use" xlink:href="#icon-chevron"></use></svg>
        </span>
    </span>
    <select v-if="shows.length > 0" class="inquiry-form__filters-select" :value="showId" @change="updateValue">
        <option disabled :value="0">Choose A Show</option>
        <option v-for="show in shows" :key="show.id" :value="show.id">{{ show.postTitle }}</option>
    </select>
</fieldset>
</template>

<script>
import scrollTo from '../../util/scrollTo';

export default {
    name: 'InquiryFormSelectShow',
    props: ['value', 'shows'],
    computed: {
        showId() {
            return this.value > 0 ? this.value : 0;
        },
    },
    methods: {
        updateValue(event) {
            this.$emit('input', Number(event.target.value));
            scrollTo('#inquiry');
        },
    },
};
</script>
