import {
    $, addAction, addFilter, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $showSingle = $('.show-single__content');
    if (!$showSingle.length) {
        return;
    }

    addFilter('css-vars/register', (styles) => {
        const newStyles = styles;
        newStyles['show-single-width'] = () => `${$showSingle.outerWidth(true)}px`;
        return newStyles;
    });
});
