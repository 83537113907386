<template>
    <v-calendar :attributes="attributes" :min-date="minDate" :max-date="maxDate" v-bind="calendarProps">
        <template #header="page">
            <div class="vc-header">
                <div class="vc-header__left-nav">
                    <a @click="page && page.movePrevMonth()">
                        {{ page.prevMonthComps.month | monthToString }}
                    </a>
                </div>
                <div class="vc-header__month">
                    {{ page.monthLabel }}
                </div>
                <div class="vc-header__right-nav">
                    <a @click="page && page.moveNextMonth()">
                        {{ page.nextMonthComps.month | monthToString }}
                    </a>
                </div>
            </div>
        </template>
        <template #day-content="{day}">
            <div class="calendar-day" v-if="!layout.usePopover">
                <div class="calendar-day__date">
                    <span>{{ day.day }}</span>
                </div>
                <performance-links v-bind="performanceDays[day.id]"/>
            </div>
        </template>
        <template #day-popover="{day}">
            <performance-links v-bind="performanceDays[day.id]"/>
        </template>
    </v-calendar>
</template>
<script>
import moment from 'moment';
import PerformanceLinks from "./PerformanceLinks";
export default {
    name: 'PerformanceCalendar',
    components: {PerformanceLinks},
    props: {
        performances: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            calendarProps: {
                isExpanded: true,
                masks: {title: 'MMMM'},
            },
        };
    },
    computed: {
        performanceDays() {
            return this.performances.reduce((days, performance) => {
                const newDays = days;
                const newPerformance = performance;
                const datetime = moment(newPerformance.datetime);
                newPerformance.time = datetime.format('h:mm A');
                newDays[newPerformance.dateId] = newDays[newPerformance.dateId] ||
                    {
                        date: datetime.toDate(),
                        label: datetime.format('h:mm:ss A'),
                        performances: []
                    };
                newDays[newPerformance.dateId].performances.push(newPerformance);
                return newDays;
            }, {});
        },
        performanceDateIds() {
            return Object.keys(this.performanceDays);
        },
        minDate() {
            const minDateId = this.performanceDateIds[0];
            return minDateId ? this.performanceDays[minDateId].date : new Date();
        },
        maxDate() {
            const maxDateId = this.performanceDateIds[this.performanceDateIds.length - 1];
            return maxDateId ? this.performanceDays[maxDateId].date : undefined;
        },
        layout() {
            return this.$screens(
                {
                    default: {
                        usePopover: true,
                    },
                    md: {
                        usePopover: false,
                    },
                },
            );
        },
        attributes() {
            return [
                ...Object.values(this.performanceDays).map(data => ({
                    dates: data.date,
                    dot: this.layout.usePopover ? {
                        color: 'none',
                        class: 'bg-primary'
                    } : null,
                    popover: this.layout.usePopover ? {
                        label: data.label,
                        visibility: 'click',
                        isInteractive: true
                    } : null,
                })),
            ]
        }
    }
};
</script>
