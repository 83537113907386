import {
    $, addAction, addFilter, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $footerHangingAnchor = $('.footer-nav__hanging-anchor');
    if (!$footerHangingAnchor.length) {
        return;
    }

    addFilter('css-vars/register', (styles) => {
        const newStyles = styles;
        newStyles['footer-nav-hanging-anchor'] = () => `${$footerHangingAnchor.outerHeight(true)}px`;
        return newStyles;
    });
});
