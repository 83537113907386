class PriceRange {
    constructor($container, config) {
        this.$container = $container;
        this.$el = $container.find('.filters__range-container');
        this.min = Math.floor(parseFloat(config.min_group_price));
        this.max = Math.ceil(parseFloat(config.max_group_price));
        this.value = false;
        this.$el.slider({
            range: true,
            min: this.min,
            max: this.max,
            values: [this.min, this.max],
            slide: this.updateUi.bind(this),
            change: this.onChange.bind(this),
        });

        this.$el.find('span:not(:last-child)').append(`<div>$${this.min}</div>`);
        this.$el.find('span:last-child').append(`<div>$${this.max}</div>`);
    }

    onChange(e, ui) {
        this.value = ui.values.join('-');

        if (this.min === ui.values[0] && this.max === ui.values[1]) {
            this.value = false;
        }

        this.updateUi(e, ui);
        this.publish();
    }

    updateUi(e, { values }) {
        this.$el.find('span > div').text(`$${values[0]}`);
        this.$el.find('span:last-child > div').text(`$${values[1]}`);
    }

    set(values) {
        this.$el.slider('values', values);
    }

    publish() {
        this.$container.trigger('filter/update');
    }

    reset() {
        this.$el.slider('values', [this.min, this.max]);
    }
}

export default PriceRange;
