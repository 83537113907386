<template>
    <div
        data-gtm="accordion-panel"
        class="accordion-panel"
        :class="{ 'accordion-panel--expanded': open }"
        data-accordion-target="0"
    >
        <h2 class="accordion-panel__heading">
            <span class="accordion-panel__headline"><slot name="headline">{{ headline }}</slot></span>
            <button type="button" v-if="editable" @click="$emit('edit')" class="accordion-panel__icon" :aria-expanded="open">
                Edit
            </button>
        </h2>
        <div class="accordion-panel__content" :aria-hidden="open ? 'false' : 'true'">
            <slot />
        </div>
    </div>

</template>
<script>
export default {
    name: 'AccordionPanel',
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        headline: {
            type: String,
            default: '',
        },
    },
};
</script>

