import {
    $, addAction, INIT, addFilter,
} from '@situation/setdesign.util';
import Performances from './Performances';
import matchPerformances from './match-performances';
import clearFilters from './clear-filters';

addAction(INIT, () => {
    if (window.hasOwnProperty('filterableGroupData')) {
        $.each(window.filterableGroupData, (groupID) => {
            addFilter(
                `filters/register/${groupID}/performances`,
                (filter, $el, filterData) => new Performances($el, filterData),
                10,
                3,
            );
        });

        addFilter('filters/match/performances', () => matchPerformances);
        clearFilters($('[data-filter-clear]'));
    }
});
