<template>
<div class="inquiry-form__ticketing" :class="isLoaded ? 'inquiry-form__ticketing--loaded' : ''">
    <div class="inquiry-form__ticketing-container row inquiry-form__row" v-if="!errorMessage">
        <inquiry-form-performance-date class="col-md-6" :performances="performances" v-model="selectedDate" override-label="Performance Date"></inquiry-form-performance-date>
        <inquiry-form-performance-time class="col-md-6" :performances="uniquePerformancesForSelectedDate" v-model="currentPerformance" override-label="Performance Time"></inquiry-form-performance-time>
        <div class="col-md-12" style="margin-top: -20px; margin-left: -5px;"><strong class="inquiry-form__note" v-if="PerfBlockNotes">{{ PerfBlockNotes }}</strong></div>
        <inquiry-form-field class="col-md-12" field-name="date_flexible" override-label="Date Flexible" hide-label>
            <div class="field__checkbox">
                <input type="checkbox" v-model="dateFlexible" name="date_flexible" id="date_flexible" />
                <label for="date_flexible"><slot name="date-flexible"></slot></label>
            </div>
        </inquiry-form-field>
        <inquiry-form-ticket-quantity class="col-md-6" v-model="ticketQuantity" :min="minQuantity" :max="5000" override-label="Ticket Quantity"></inquiry-form-ticket-quantity>
        <inquiry-form-sections class="col-md-6" :pricing-tier="currentPricingTier" v-model="section" override-label="Section"></inquiry-form-sections>
    </div>
    <div class="inquiry-form__ticketing-errors" :class="'message--' + errorLevel" v-if="errorMessage">
        <p>{{ errorMessage }}</p>
    </div>
    <div class="field__info text--center" v-if="isLoaded">
        <a class="field__info-btn" :href="infoLink.url" target="_blank">{{ infoLink.label }}</a>
    </div>
    <div class="inquiry-form__ticketing-pricing-info" v-if="hasPricing">
        <slot></slot>
    </div>
</div>
</template>
<script>
import { apiPost } from '@situation/setdesign.util';
import moment from 'moment';

export default {
    name: 'InquiryFormTicketing',
    props: ['showId', 'pid', 'value'],
    data() {
        return {
            show: [],
            performances: [],
            pricingTiers: [],
            errorMessage: '',
            errorLevel: 'danger',
            isLoaded: false,
            date: '',
            initialPerformanceId: this.pid,
        };
    },
    computed: {
        selectedDate: {
            get() {
                return this.date;
            },
            set(date) {
                this.date = date;
                this.currentPerformance = this.uniquePerformancesForSelectedDate.length === 1
                    ? this.uniquePerformancesForSelectedDate[0] : false;
            },
        },
        currentPerformance: {
            get() {
                return this.value.performance_id
                    ? this.getPerformanceByID(this.value.performance_id) : false;
            },
            set(performance) {
                this.update('section', '');
                this.update('performance_id', performance.id || '');
            },
        },
        ticketQuantity: {
            get() {
                return this.value.ticket_quantity;
            },
            set(ticketQuantity) {
                this.update('ticket_quantity', ticketQuantity);
            },
        },
        section: {
            get() {
                return this.value.section;
            },
            set(section) {
                this.update('section', section);
            },
        },
        dateFlexible: {
            get() {
                return this.value.date_flexible
            },
            set(value) {
                this.update('date_flexible', value);
            }
        },
        PerfBlockNotes() {
            if(this.currentPricingTier == null) return '';
            else return this.currentPricingTier.perfBlockNotes;
        },
        currentPricingTier() {
            const { pricingTierId } = this.currentPerformance;
            return this.pricingTiers.find((pt) => pt.id === pricingTierId);
        },
        minQuantity() {
            try {
                return this.show.ticketQuantity.slug;
            } catch (e) {
                return 0;
            }
        },
        uniquePerformancesForSelectedDate() {
            return this.performancesForSelectedDate && this.performancesForSelectedDate.reduce((acc, performance) => {
                if (performance.status === 'A'  && !acc.some(existingPerformance => existingPerformance.foxtrotPerformanceId === performance.foxtrotPerformanceId)) {
                    acc.push(performance);
                }
                return acc;
            }, []);
        },
        performancesForSelectedDate() {
            if (!this.selectedDate) {
                return [];
            }
            const date = moment(this.selectedDate);
            return this.performances.filter((performance) => date.isSame(performance.datetime, 'day'));
        },
        hasPricing() {
            return this.show.calendarPricingUrl && !this.errorMessage;
        },
        infoLink() {
            return this.hasPricing ?
                {url: this.show.calendarPricingUrl, label: 'See Full Calendar and Pricing'} :
                {url: this.show.url, label: 'See Show Details'};
        }
    },
    methods: {
        update(key, value) {
            const ticketing = this.value;
            ticketing[key] = value;
            this.$emit('input', ticketing);
        },
        clearTicketing() {
            this.$emit('input', { performance_id: '', ticket_quantity: '', section: '' });
            this.date = '';
            this.errorMessage = '';
        },
        getPerformanceByID(id) {
            return this.performances.length ? this.performances.find((p) => p.id === id) : false;
        },
        fetchShowData(value) {
            this.clearTicketing();
            this.isLoaded = false;
            if (!value) {
                return;
            }
            apiPost('inquiry-form/show', {
                selected_show_id: value,
            }).success((data) => {
                this.show = data.show;
                if (data.message) {
                    this.errorLevel = 'warning';
                    this.errorMessage = data.message;
                    return;
                }
                this.performances = data.performances.map((p) => {
                    const np = p;
                    np.datetime = moment(np.datetime);
                    np.dateLabel = np.datetime.format('h:mm A');
                    return np;
                });
                this.pricingTiers = data.pricingTiers;
                if (this.initialPerformanceId) {
                    this.setInitialPerformance(this.initialPerformanceId);
                    this.initialPerformanceId = false;
                }
            }).error((xhr) => {
                this.errorLevel = 'danger';
                this.errorMessage = xhr.responseJSON.message;
            }).complete(() => {
                this.isLoaded = true;
            });
        },
        setInitialPerformance(id) {
            const performance = this.getPerformanceByID(id);
            if (performance) {
                this.date = performance.datetime.toDate();
                setTimeout(() => {
                    this.currentPerformance = performance;
                }, 15);
            }
        },
    },
    watch: {
        showId: {
            handler(value) {
                this.fetchShowData(value);
            },
            immediate: true,
        }
    },
};
</script>
