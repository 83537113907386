import { $ } from '@situation/setdesign.util';
import moment from 'moment';
import dateFormat from './vars';

function parseQuery(query) {
    const args = {};
    const queryObj = JSON.parse($.isArray(query) ? query[0] : query);
    if (queryObj.hasOwnProperty('date')) {
        const dateRange = queryObj.date.split(',').map((date) => moment(date, dateFormat));
        args.date = {
            start: moment(dateRange[0]).startOf('day'),
            end: moment(dateRange[dateRange.length - 1]).endOf('day'),
        };
    }
    if (queryObj.hasOwnProperty('price')) {
        const price = queryObj.price.split('-');
        args.price = { min: parseFloat(price[0]), max: parseFloat(price[price.length - 1]) + 1 };
    }
    return args;
}

function parseMatches(haystack) {
    return haystack.map((item) => ({
        date: moment(item[0]),
        min: parseFloat(item[1] || 0),
        max: parseFloat(item[2] || 0),
    }));
}

function matchDateRange(range, date) {
    return range.start.isSameOrBefore(date) && range.end.isAfter(date);
}

function matchPriceRange(range1, range2) {
    return range1.min <= range2.max && range1.max > range2.min;
}

/**
 * `this` scoped to FilterableItem
 * @param needle
 * @param haystack
 * @return {boolean}
 */
export default function (needle, haystack) {
    const args = parseQuery(needle);
    let matches = parseMatches(haystack);
    if (args.hasOwnProperty('date')) {
        matches = matches.filter((match) => matchDateRange(args.date, match.date));
    }
    if (args.hasOwnProperty('price')) {
        matches = matches.filter((match) => matchPriceRange(args.price, match));
    }
    return !!matches.length;
}
