import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $menuHowItWorks = $('.header-nav .menu-how-it-works > a');
    $menuHowItWorks.prepend('<svg class="icon"><use href="#icon-gear"></use></svg>');
    const $menuAllShows = $('.header-nav .menu-all-shows > a');
    $menuAllShows.prepend('<svg class="icon"><use href="#icon-star"></use></svg>');
    const $menuGroupTickets = $('.header-nav .menu-group-tickets > a');
    $menuGroupTickets.prepend('<svg class="icon"><use href="#icon-ticket"></use></svg>');
    const $footerHangingAnchor = $('.footer-nav__hanging-anchor > a');
    $footerHangingAnchor.prepend('<svg class="icon"><use href="#icon-blurb"></use></svg>');
});
