import { addFilter } from '@situation/setdesign.util';

addFilter('media-carousel/owl-settings', (settings) => {
    const newSettings = settings;
    newSettings.navText = [
        '<svg class="icon icon-direction-right"><use xlink:href="#icon-chevron"></use></svg>',
        '<svg class="icon icon-direction-left"><use xlink:href="#icon-chevron"></use></svg>',
    ];
    return newSettings;
});
