<template>
    <inquiry-form-field field-name="ticket_quantity" :min="min">
        <input v-model="quantity" type="number" name="ticket_quantity" @input="onInput" :min="min" :max="max" :placeholder="min + '+'" required  />
    </inquiry-form-field>
</template>
<script>
export default {
    name: 'InquiryFormTicketQuantity',
    props: ['min', 'max', 'value'],
    computed: {
        quantity: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        onInput(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>
