import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $filters = $('.filters__item');
    let $openFilter = false;

    const closeFilters = () => {
        $filters.removeClass('filters__item--active');
        $filters.find('.filters__button').attr('aria-expanded', 'false');
    };

    $filters.each((i, el) => {
        const $filter = $(el);
        const $filterLegend = $filter.find('.filters__label');
        const $filterLegendText = $filterLegend.text();
        const $btn = $(
            `<button class="filters__button" role="button" aria-expanded="false">${$filterLegendText}<span class="icon icon-chevron icon-direction-up"><svg><use xlink:href="#icon-chevron"></use></svg></span></button>`,
        );
        $filterLegend.before($btn);
        $btn.on('click', (e) => {
            const $this = $(e.target);
            $openFilter = false;
            const isExpanded = $this.attr('aria-expanded') === 'true';
            closeFilters();

            if (!isExpanded) {
                $filter.toggleClass('filters__item--active');
                $this.attr('aria-expanded', 'true');
                $openFilter = $filter;
            }
        });
    });

    $(document).on('click', (e) => {
        if ($openFilter && $openFilter.has(e.target).length === 0) {
            closeFilters();
        }
    });
});
