<template>
    <validate tag="fieldset" class="field" :custom="{ [fieldName]: validate }" :debounce="debounce">
        <label :class="labelClass">{{fieldLabel }}</label>
        <slot></slot>
        <field-messages class="field__message" :name="fieldName" show="($dirty && $touched) || $submitted">
            <div class="message--danger" slot="required">{{ requiredMessage }}</div>
            <div class="message--danger" slot="min">{{ minMessage }}</div>
            <div class="message--danger" :slot="fieldName">{{ invalidMessage }}</div>
        </field-messages>
    </validate>
</template>

<script>
export default {
    name: 'InquiryFormField',
    props: {
        fieldName: { type: String, required: true },
        overrideLabel: { type: String, default: '' },
        hideLabel: { type: Boolean, default: false },
        successMessage: { type: String, default: 'Success!' },
        min: { type: [String, Number], default: 0 },
        validate: { type: Function, default: () => true },
        validateMessage: { type: String, default: '' },
        delayValidation: { type: Boolean, default: false }
    },
    computed: {
        debounce() {
            return this.delayValidation ? 500 : 0
        },
        fieldLabel() {
            return this.overrideLabel || this.capitalize(this.fieldName.replace(/_/g, ' '));
        },
        labelClass() {
            const classes = ['field__label'];
            if (this.hideLabel) {
                classes.push('screen-reader-text');
            }
            return classes.join(' ');
        },
        requiredMessage() {
            return `${this.fieldLabel} is a required field.`;
        },
        invalidMessage() {
            return this.validateMessage || `${this.fieldLabel} is invalid.`;
        },
        minMessage() {
            return `${this.fieldLabel} requires a minimum value of ${this.min}.`;
        },
    },
    methods: {
        capitalize(value) {
            if (!value) {
                return '';
            }
            return value.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
        },
    },
};

</script>
