import { $, doAction } from '@situation/setdesign.util';

export default function ($el) {
    const group = $el.data('filterClear');
    $.address.change((event) => {
        const matches = (event.parameterNames || []).filter((param) => param.indexOf(group) >= 0);
        $el.toggleClass('filters__clear--show', !!matches.length);
    });

    $el.on('click', (e) => {
        e.preventDefault();
        doAction(`filters/clear/${group}`);
    });
}
