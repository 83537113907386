<template>

    <div data-gtm="accordion" class="accordion accordion--form">
        <AccordionPanel
            headline="Your Email"
            :open="current_page === 0"
            :editable="current_page > 0"
            @edit="onEdit(0)"
        >
            <vue-form class="inquiry-form" v-show="formVisible" :state="formstate_1" @submit.prevent="onNext(formstate_1, 0)" >
                <div class="inquiry-form__row row">
                    <inquiry-form-field
                        class="col-md-6 col-lg-4"
                        field-name="email"
                        override-label="Email Address"
                    >
                        <input
                            v-model="model.email"
                            type="email"
                            name="email"
                            placeholder="Email"
                            required
                            ref="emailInput"
                            @input="formstate_1.confirm_email._validate()"
                        />
                    </inquiry-form-field>
                    <inquiry-form-field
                        class="col-md-6 col-lg-4"
                        field-name="confirm_email"
                        override-label="Confirm Email Address"
                        validate-message="Email Addresses do not match"
                        :validate="emailsMatch"
                    >
                        <input
                            v-model="model.confirmEmail"
                            type="email"
                            name="confirm_email"
                            placeholder="Email"
                            required
                            ref="confirmEmailInput"
                        />
                    </inquiry-form-field>
                    <inquiry-form-field class="col-md-12" field-name="agree_to_request" override-label="Agreement" hide-label>
                        <div class="field__checkbox">
                            <input type="checkbox" v-model="model.agree_to_request" name="agree_to_request" id="agree_to_request" required />
                            <label for="agree_to_request"><span v-html="labels.agreeToTicketRequest"></span></label>
                        </div>
                    </inquiry-form-field>
                </div>
                <div class="inquiry-form__submit">
                    <p><button type="submit" class="button">Next</button></p>
                </div>
            </vue-form>
        </AccordionPanel>
        <AccordionPanel
            headline="Your Ticket Inquiry"
            :open="current_page === 1"
            :editable="current_page > 1"
            @edit="onEdit(1)"
        >
            <vue-form class="inquiry-form" v-show="formVisible" :state="formstate_2" @submit.prevent="onNext(formstate_2, 1)" >
                <div class="inquiry-form__legend" v-show="formVisible">
                    <div class="inquiry-form__legend-selector" v-if="!experienceSelected">
                        <inquiry-form-select-show :shows="shows" v-model="model.show_id"></inquiry-form-select-show>
                        <p>or</p>
                        <fieldset>
                            <button class="inquiry-form__button" @click="onDeselectShow">Get a Recommendation</button>
                        </fieldset>
                    </div>
                </div>
                <inquiry-form-ticketing v-if="showSelected" v-model="ticketing" :pid="performanceId" :show-id="model.show_id">
                    <template #date-flexible><span v-html="labels.dateFlexibleContent"></span></template>
                    <slot name="pricing-info"></slot>
                </inquiry-form-ticketing>
                <div class="inquiry-form__submit">
                    <p><button type="submit" class="button" :disabled="submitting" v-if="showSelected">Next</button></p>
                </div>
            </vue-form>
        </AccordionPanel>
        <AccordionPanel
            headline="Contact Details"
            :open="current_page === 2"
            :editable="current_page > 2"
            @edit="onEdit(2)"
        >
            <vue-form class="inquiry-form" v-show="formVisible" :state="formstate_3" @submit.prevent="onNext(formstate_3, 2)" >
                <div class="row inquiry-form__row">
                    <inquiry-form-field class="col-md-4" field-name="first_name" override-label="First Name">
                        <input v-model="model.first_name" type="text" name="first_name" required />
                    </inquiry-form-field>
                    <inquiry-form-field class="col-md-4" field-name="last_name" override-label="Last Name">
                        <input v-model="model.last_name" type="text" name="last_name" required/>
                    </inquiry-form-field>
                    <inquiry-form-field class="col-md-4" field-name="organization_name" override-label="Organization">
                        <input v-model="model.organization_name" type="text" name="organization_name" required/>
                    </inquiry-form-field>
                    <inquiry-form-field class="col-md-4" field-name="phone_number" override-label="Phone Number">
                        <input v-model="model.phone_number" type="text" name="phone_number" required/>
                    </inquiry-form-field>
                    <!-- validate-message="Please select your full address from the dropdown options"
                        :validate="fullAddressFilled"-->
                    <inquiry-form-field
                        class="col-md-8"
                        field-name="address_full"
                        override-label="Mailing Address"
                        :delay-validation="!resettingAddress"
                    >
                        <div class="d-flex">
                        <input
                            v-model="model.address_full"
                            type="text"
                            name="address_full"
                            ref="address_full"
                            autocomplete="off"
                            :readonly="fullAddressFilled"
                            :class="{ disabled: fullAddressFilled }"
                            required
                            @blur="maybeClearAddressFields"
                        />
                        <button class="button button--secondary align-self-center" v-if="fullAddressFilled" @click="editAddress">
                            Edit
                        </button>
                        </div>
                    </inquiry-form-field>
                </div>
                <div class="inquiry-form__submit">
                    <p><button type="submit" class="button" :disabled="submitting">Next</button></p>
                </div>
            </vue-form>
        </AccordionPanel>
        <AccordionPanel
            headline="Confirmation"
            :open="current_page === 3"
        >
            <vue-form class="inquiry-form" v-show="formVisible" :state="formstate_4" @submit.prevent="onSubmit" >
                <div class="inquiry-form__content" v-html="labels.confirmationContent"></div>
                <inquiry-form-field field-name="agree_to_terms" override-label="Agreement" hide-label>
                    <div class="field__checkbox">
                        <input type="checkbox" v-model="model.agree_to_terms" name="agree_to_terms" id="agree_to_terms" required />
                        <label for="agree_to_terms"><span v-html="labels.agreeToTermsContent"></span></label>
                    </div>
                </inquiry-form-field>
                <div class="g-recaptcha">Error loading reCAPTCHA. Please reload the page.</div>
                <div class="field__message" v-if="response && isError">
                    <div class="message--danger" v-html="response"></div>
                </div>
                <div class="field__message" v-if="!response && waiting">
                    <div class="message--info">Request is still processing, but is taking a little longer than usual. Please be patient and do not navigate away from this page.</div>
                </div>
                <div class="inquiry-form__submit">
                    <p><button type="submit" class="button" :disabled="!recaptcha || submitting" :class="{ loading: submitting }">Submit</button></p>
                </div>
            </vue-form>
        </AccordionPanel>
        <div class="field__message" v-if="response && !isError">
            <div class="message--success" v-html="response"></div>
        </div>
    </div>

</template>

<script>
import { apiPost } from '@situation/setdesign.util';
import regions from '../../util/regions';
import scrollTo from "../../util/scrollTo";
import AccordionPanel from "../accordion/AccordionPanel";

export default {
    name: 'inquiry-form',
    components: {AccordionPanel},
    props: {
        shows: Array,
        showId: {
            type: Number,
            default: 0,
        },
        performanceId: {
            type: Number,
            default: 0,
        },
        experienceId: {
            type: Number,
            default: 0,
        },
        labels: {
            type: Object
        },
        address: {
            type: String,
            default: '',
        },
        recaptchaKey: {
            type: String,
            default: ''
        }
    },
    mounted() {
        const addressComponentMap = {
            street_number: 'long_name',
            route: 'long_name',
            locality: 'long_name',
            sublocality: 'long_name',
            sublocality_level_1: 'long_name',
            administrative_area_level_3: 'long_name',
            country: 'short_name',
            postal_code: 'long_name',
            administrative_area_level_1: 'short_name',
        }
        const autocomplete = new google.maps.places.Autocomplete(this.$refs.address_full, { types: ['address'] });
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) return;
            const addressComponents = place.address_components.reduce((carry, item) => {
                const addressType = item.types[0];
                if (addressComponentMap[addressType]) {
                    carry[addressType] = item[addressComponentMap[addressType]];
                }
                return carry
            }, {});

            // Address
            this.model.address_line1 = [addressComponents.street_number, addressComponents.route]
                .filter(Boolean)
                .join(' ')

            // City
            this.model.city = addressComponents.locality
                ? addressComponents.locality
                : addressComponents.sublocality
                    ? addressComponents.sublocality
                    : addressComponents.sublocality_level_1
                        ? addressComponents.sublocality_level_1
                        : addressComponents.administrative_area_level_3 && addressComponents.administrative_area_level_3

            // State
            this.model.state_code = addressComponents.administrative_area_level_1;

            // Country
            this.model.country_code = addressComponents.country;

            // Postal Code
            this.model.postal_code = addressComponents.postal_code;

            // For Value in Field
            this.model.address_full = place.formatted_address;
            this.formatted_address = place.formatted_address;
        });
        this.renderRecaptcha()
    },
    data() {
        const getLS = JSON.parse(sessionStorage.getItem('user'));
        return {
            formstate_1: {},
            formstate_2: {},
            formstate_3: {},
            formstate_4: {},
            current_page: 0,
            response: '',
            waiting: false,
            isError: true,
            recaptcha: false,
            submitting: false,
            regions,
            formatted_address: '',
            resettingAddress: false,
            model: {
                show_id: this.showId || this.experienceId,
                date_flexible: false,
                performance_id: '',
                ticket_quantity: '',
                section: '',
                first_name: getLS ? getLS.first_name : '',
                last_name: getLS ? getLS.last_name : '',
                email: getLS ? getLS.email : '',
                confirmEmail: '',
                organization_name: getLS ? getLS.organization_name : '',
                phone_number: getLS ? getLS.phone_number : '',
                address_full: getLS ? getLS.address_full : '',
                address_line1: getLS ? getLS.address_line1 : '',
                address_line2: '',
                city: getLS ? getLS.city : '',
                country_code: getLS ? getLS.country_code : '',
                state_code: getLS ? getLS.state_code : '',
                postal_code: getLS ? getLS.postal_code : '',
                desired_date: '',
                customer_notes: '',
                order_addons: [],
                agree_to_request: '',
                agree_to_terms: '',
            },
        };
    },
    computed: {
        showSelected() {
            return this.model.show_id > 0 && !this.experienceSelected;
        },
        experienceSelected() {
            return this.experienceId > 0;
        },
        statesForCurrentCountry() {
            return this.model.country_code
                ? this.regions.filter(
                    (country) => country.code === this.model.country_code,
                )[0].states
                : null;
        },
        formVisible() {
            return !(this.response && !this.isError);
        },
        ticketing: {
            get() {
                return {
                    performance_id: this.model.performance_id,
                    ticket_quantity: this.model.ticket_quantity,
                    section: this.model.section,
                    date_flexible: this.model.date_flexible
                };
            },
            set(newValue) {
                this.model.performance_id = newValue.performance_id;
                this.model.ticket_quantity = newValue.ticket_quantity;
                this.model.section = newValue.section;
                this.model.date_flexible = newValue.date_flexible;
            },
        },
        fullAddressFilled() {
            return !!(
                this.model.address_line1 && this.model.city && this.model.state_code && this.model.country_code && this.model.postal_code
            );
        },
    },
    methods: {
        emailsMatch(value) {
            if (!(value && this.model.email)) {
                return true
            }
            return value === this.model.email
        },
        maybeClearAddressFields() {
            if (this.model.address_full !== this.formatted_address) {
                this.clearAddressFields()
            }
        },
        editAddress() {
            this.resettingAddress = true
            this.clearAddressFields()
            this.$refs.address_full.focus()
            this.resettingAddress = false
        },
        clearAddressFields() {
            this.model.address_full = this.model.address_line1 = this.model.city = this.model.state_code = this.model.country_code = this.model.postal_code = '';
            this.formstate_3.address_full._setUntouched()
        },
        renderRecaptcha() {
            if (this.recaptcha || !(window.grecaptcha && window.grecaptcha.enterprise && window.grecaptcha.enterprise.render)) {
                return
            }
            const targetRecaptcha = document.querySelector('.g-recaptcha');
            window.grecaptcha.enterprise.render(targetRecaptcha, {
                sitekey: this.recaptchaKey,
            });
            this.recaptcha = true;
        },
        onEdit(target_page) {
            this.current_page = target_page;
        },
        onNext(formstate_page) {
            if (formstate_page.$invalid) {
                return;
            }


            // let newObject
            const newSessionObject = {
                first_name: '',
                last_name: '',
                organization_name: '',
                email: '',
                phone_number: '',
                confirm_email: '',
                address_full: '',
                address_line1: '',
                address_line2: '',
                city: '',
                country_code: '',
                state_code: '',
                postal_code: '',
            };

            // get LS values
            const currentSession = Object.assign(JSON.parse(sessionStorage.getItem('user')) || {}, newSessionObject);

            // Iterate each submitted value from form
            Object.keys(currentSession).forEach((key) => {
                currentSession[key] = this.model[key];
            });

            sessionStorage.setItem('user', JSON.stringify(currentSession));

            this.current_page++;
            // attempt to load recaptcha if not already loaded
            this.renderRecaptcha()
        },
        onSubmit() {
            // get LS values
            const currentSession = JSON.parse(sessionStorage.getItem('user'));
            this.response = '';
            if (
                this.formstate_1.$invalid || this.formstate_2.$invalid
                || this.formstate_3.$invalid || this.formstate_4.$invalid
            ) {
                return;
            }
            this.submitting = true;

            if (window.grecaptcha.enterprise.getResponse()) {
                apiPost('inquiry-form/submit', this.model)
                    .done((data) => {
                        this.isError = false;
                        this.response = data.message;
                        this.current_page = -1 // so all panels collapse and are not editable
                    })
                    .fail((response) => {
                        this.isError = true;
                        this.response = response.responseJSON.message;
                    })
                    .complete(() => {
                        localStorage.setItem('user', JSON.stringify(currentSession));
                        this.submitting = false;
                        this.waiting = false;

                    });
                setTimeout(() => {
                    this.waiting = true;
                }, 5000);
            } else {
                this.isError = true;
                this.submitting = false;
                this.response = 'Please verify recaptcha.';
            }
        },
        onCountryChange() {
            this.model.state_code = '';
        },
        onDeselectShow() {
            this.model.show_id = -1;
            this.model.performance_id = '';
            this.model.ticket_quantity = '';
            this.model.section = '';
            scrollTo('#inquiry');
        },
    },
};
</script>
