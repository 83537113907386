import { $ } from '@situation/setdesign.util';
import DatePicker from './DatePicker';
import PriceRange from './PriceRange';

function Performances($el, filterData) {
    this.init($el, filterData);
    this.datePicker = new DatePicker(
        this.$el.filter('.filters__item--date'),
        this.config.available_performance_dates,
    );

    this.price = new PriceRange(this.$el.filter('.filters__item--price'), this.config);
    this.$el.on('filter/update', this.onChange.bind(this));
}

$.extend(Performances.prototype, SetDesign.Filters.filterBase, {
    onChange() {
        const value = {};
        if (this.datePicker.value) {
            value.date = this.datePicker.value;
        }
        if (this.price.value) {
            value.price = this.price.value;
        }

        this.value = $.isEmptyObject(value) ? '' : JSON.stringify(value);
        this.publish();
    },
    set(values) {
        const newValues = JSON.parse(values);
        if (newValues.hasOwnProperty('date')) {
            this.datePicker.set(newValues.date.split(','));
        }
        if (newValues.hasOwnProperty('price')) {
            this.price.set(newValues.price.split('-'));
        }

        this.onChange();
    },
    clear() {
        this.datePicker.reset();
        this.price.reset();
    },
});

export default Performances;
