import {
    $, addAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const NARROW_BREAKPOINT = 345;
    const EXPANSE_BREAKPOINT = 768;
    const NARROW_CLASS = 'responsive-component--narrow';
    const EXPANSE_CLASS = 'responsive-component--expanse';
    const $components = $('.responsive-component');
    if (!$components.length) {
        return;
    }

    const toggleComponentClasses = (components) => {
        components.each((key, component) => {
            const $component = $(component);
            if ($component.width() === 0) {
                return;
            }

            $component.toggleClass(NARROW_CLASS, $component.width() <= NARROW_BREAKPOINT);
            $component.toggleClass(EXPANSE_CLASS, $component.width() >= EXPANSE_BREAKPOINT);
        });
    };

    toggleComponentClasses($components);
    addAction(LAYOUT, () => {
        toggleComponentClasses($components);
    });
});
