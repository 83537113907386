import { addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const floatingAction = document.querySelector('.floating-action');
    const open = document.querySelector('[data-floating-open]');
    const close = document.querySelector('[data-floating-close]');
    if (floatingAction && open && close) {
        const box = floatingAction.querySelector('.floating-action__content');
        open.addEventListener('click', (e) => {
            e.preventDefault();
            floatingAction.classList.add('active');
            box.ariaHidden = 'false';
            box.tabIndex = 1;
        });

        close.addEventListener('click', (e) => {
            e.preventDefault();
            floatingAction.classList.remove('active');
            box.ariaHidden = 'true';
            box.tabIndex = -1;
        });
    }
});
