<template>
    <fieldset class="field">
        <label v-if="uniquePerformances.length">Performance Time</label>
        <div class="field__outline field__outline--radio" v-if="uniquePerformances.length">
            <div class="field__radio" v-for="performance in uniquePerformances" :key="performance.id">
                <input v-model="selectedPerformanceID" type="radio" :id="'performance-' + performance.id" name="selected-performance" :value="performance.id">
                <label :for="'performance-'+performance.id">{{ performance.dateLabel }}</label>
            </div>
        </div>
    </fieldset>
</template>

<script>
export default {
    name: 'InquiryFormPerformanceTime',
    props: ['performances', 'value'],
    computed: {
        uniquePerformances() {
            return this.performances && this.performances.reduce((acc, performance) => {
                if (!acc.some(existingPerformance => existingPerformance.foxtrotPerformanceId === performance.foxtrotPerformanceId)) {
                    acc.push(performance);
                }
                return acc;
            }, []);
        },
        selectedPerformanceID: {
            get() {
                return this.value.id;
            },
            set(value) {
                this.$emit('input', this.currentPerformance(value));
            },
        },
    },
    methods: {
        currentPerformance(id) {
            return this.performances.find((performance) => performance.id === id);
        },
    },
};
</script>
