import { $, addAction, LAYOUT } from '@situation/setdesign.util';

addAction(LAYOUT, () => {
    $('.action-card').each((i, el) => {
        const $this = $(el);
        const $drawer = $this.find('.action-card__drawer');
        const $content = $this.find('.action-card__content');
        $content.css('margin-top', '');
        $drawer.css('max-height', '');

        if ($drawer.is(':hidden')) {
            return;
        }

        $drawer.addClass('expanded');
        const height = $drawer.height();
        $drawer.removeClass('expanded');
        $drawer.css('max-height', height);
        $content.css('margin-top', -1 * height);
    });
});
