import moment from 'moment';
import { $ } from '@situation/setdesign.util';
import dateFormat from './vars';

function getRange(dates) {
    const newDates = dates.map((item) => moment(item));
    return [moment.min(newDates), moment.max(newDates)];
}

function format(date) {
    return date ? moment(date).format(dateFormat) : date;
}

class DatePicker {
    constructor($container, dates) {
        this.value = false;
        this.$container = $container;
        this.range = getRange(dates);
        this.$all = $container.find('.datepicker');
        this.$single = this.$all.filter('[data-date-type="single"]');
        this.$min = this.$all.filter('[data-date-type="min"]');
        this.$max = this.$all.filter('[data-date-type="max"]');
        this.$all.datepicker({ dateFormat: 'mm/dd/yy' }).on('change', ({ target }) => {
            this.onChange($(target).data('dateType'));
        });

        this.updateRange();
    }

    updateRange() {
        this.$all.datepicker('option', 'minDate', this.range[0].toDate());
        this.$all.datepicker('option', 'maxDate', this.range[1].toDate());
        const range = [this.$min.datepicker('getDate'), this.$max.datepicker('getDate')];
        if (range[0]) {
            this.$max.datepicker('option', 'minDate', range[0]);
        }
        if (range[1]) {
            this.$min.datepicker('option', 'maxDate', range[1]);
        }
    }

    onChange(type) {
        switch (type) {
            case 'single':
                this.$min.val('');
                this.$max.val('');
                this.value = format(this.$single.datepicker('getDate'));
                break;
            case 'min':
            case 'max':
                this.$single.val('');
                this.value = [
                    format(this.$min.datepicker('getDate')),
                    format(this.$max.datepicker('getDate')),
                ];

                break;
            default:
                break;
        }

        if (type === 'min') {
            setTimeout(() => {
                this.$max.focus();
            }, 250);
        }
        if ($.isArray(this.value) && this.value.filter(Boolean).length !== 2) {
            this.value = false;
        }
        if ($.isArray(this.value)) {
            this.value = this.value.join(',');
        }

        this.updateRange();
        this.publish();
    }

    publish() {
        this.$container.trigger('filter/update');
    }

    set(value) {
        const dates = value.map((item) => moment(item, dateFormat));
        if (value.length === 2) {
            this.$min.datepicker('setDate', dates[0].toDate());
            this.$max.datepicker('setDate', dates[1].toDate());
            this.onChange('max');
        } else {
            this.$single.datepicker('setDate', dates[0].toDate());
            this.onChange('single');
        }
    }

    reset() {
        this.$all.val('').trigger('change');
    }
}

export default DatePicker;
